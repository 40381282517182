'use client';
import {
	ESTIMATEDTIMEICON,
	icon_congress,
	ORDERIDICON,
	ORDERPLACEDICON,
	SMSICON,
	TOTALORDERAMOUNTICON
} from '@/assets/icons';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styles from './AcknowledgeCongratulations.module.scss';
const AcknowledgeCongratulations = ({
	title = 'Congratulations!',
	message = 'Order placed successfully' as any,
	isCollapsible = true,
	step = 1,
	showStep = true,
	data
}) => {
	const [viewToggle, setViewToggle] = useState(!isCollapsible);
	const [maxHeight, setMaxHeight] = useState('0px');
	const contentRef = useRef(null);

	useEffect(() => {
		if (contentRef.current) {
			setMaxHeight(viewToggle ? `${contentRef.current.scrollHeight}px` : '0px');
		}
	}, [viewToggle]);

	return (
		<>
			<div className={styles.confirmModal}>
				{/* <div className={`${styles.icon}`} style={{background:"red"}}>
				<LottiePlayer animationData={OderSuccess} loop={false} autoplay={true} height='100px'  width='100px'/>
			</div> */}
				<div className={`${styles.icon}`}>
					<Image src={icon_congress} width={56} height={56} alt='congress' />
				</div>

				<div className={styles.content}>
					<div className={styles.title}>{title}</div>
					{message && <div className={styles.message}>{message}</div>}
				</div>
				{showStep && (
					<>
						{step === 1 ? (
							<div style={{ marginTop: '20px' }}>
								<div className={styles.timeline}>
									<div>
										<Image src={ORDERPLACEDICON} width={20} height={20} alt='congress' />
									</div>
									<div className={styles.line}></div>
									<div>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											viewBox='0 0 20 20'
											fill='none'>
											<path
												d='M0.625 10C0.625 4.82233 4.82233 0.625 10 0.625C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625 10Z'
												stroke='#EEEFF2'
												stroke-width='1.25'
											/>
											<circle cx='10.0003' cy='9.99996' r='3.33333' fill='#CBD5E0' />
										</svg>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										gap: 45
									}}>
									<div>
										<p className={styles.green_text}>Order placed</p>
									</div>
									<div>
										<p style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}> Pay Online</p>
									</div>
								</div>
							</div>
						) : (
							<div style={{ marginTop: '20px', marginRight: '25px', marginBottom: '20px' }}>
								<div className={styles.timeline} style={{ marginLeft: '20px' }}>
									<div>
										<Image src={ORDERPLACEDICON} width={20} height={20} alt='congress' />
									</div>
									<div className={styles.line_placed}></div>
									<div>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='21'
											viewBox='0 0 20 21'
											fill='none'>
											<path
												d='M0.625 10.5C0.625 5.32233 4.82233 1.125 10 1.125C15.1777 1.125 19.375 5.32233 19.375 10.5C19.375 15.6777 15.1777 19.875 10 19.875C4.82233 19.875 0.625 15.6777 0.625 10.5Z'
												fill='#0BA259'
											/>
											<path
												d='M0.625 10.5C0.625 5.32233 4.82233 1.125 10 1.125C15.1777 1.125 19.375 5.32233 19.375 10.5C19.375 15.6777 15.1777 19.875 10 19.875C4.82233 19.875 0.625 15.6777 0.625 10.5Z'
												stroke='#0BA259'
												stroke-width='1.25'
											/>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M14.2473 6.65838L8.28066 12.4167L6.69733 10.7251C6.40566 10.4501 5.94733 10.4334 5.614 10.6667C5.289 10.9084 5.19733 11.3334 5.39733 11.6751L7.27233 14.7251C7.45566 15.0084 7.77233 15.1834 8.13066 15.1834C8.47233 15.1834 8.79733 15.0084 8.98066 14.7251C9.28066 14.3334 15.0057 7.50838 15.0057 7.50838C15.7557 6.74172 14.8473 6.06672 14.2473 6.65005V6.65838Z'
												fill='white'
											/>
										</svg>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										gap: 40
									}}>
									<div>
										<p className={styles.green_text} style={{ paddingRight: '20px' }}>
											Order placed
										</p>
									</div>
									<div>
										<p className={styles.green_text}>Paid</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>

			<div>
				{viewToggle === false ? (
					<p
						className={styles.text_style}
						style={{
							color: '#0E7673',
							fontSize: '14px',
							fontWeight: '500',
							marginTop: '20px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '5px'
						}}
						onClick={() => setViewToggle(!viewToggle)}>
						View Details{' '}
						<MdKeyboardArrowDown
							style={{ color: '#0E7673', fontSize: '18px', fontWeight: '400', cursor: 'pointer' }}
						/>
					</p>
				) : (
					<div>
						<div>
							<div className={styles.parent}>
								<Image src={TOTALORDERAMOUNTICON} width={20} height={20} alt='TOTALORDERAMOUNTICON' />
								<div>
									<p className={styles.title}>Total Order Amount</p>
									<p
										className={styles.subTitle}
										style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
										৳ {data?.po_payable_total}
									</p>
								</div>
							</div>
							<div className={styles.parent}>
								<Image src={ORDERIDICON} width={20} height={20} alt='ORDERIDICON' />
								<div>
									<p className={styles.title}>Order ID</p>
									<p
										className={styles.subTitle}
										style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
										# {data?.po_id}
									</p>
								</div>
							</div>
							<div className={styles.parent}>
								<Image src={ESTIMATEDTIMEICON} width={20} height={20} alt='ESTIMATEDTIMEICON' />
								<div>
									<p className={styles.title}>Estimated Time</p>
									<p
										className={styles.subTitle}
										style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
										{data.delivery_time}
									</p>
								</div>
							</div>
							<div className={styles.parent}>
								<Image src={SMSICON} width={20} height={20} alt='SMSICON' />
								<div>
									<p className={styles.title}>Confirmation will be sent to your Arogga App, SMS</p>
									<p
										className={styles.subTitle}
										style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
										Check your Arogga App or Phone SMS
									</p>
								</div>
							</div>
						</div>
						{isCollapsible && (
							<p
								className={styles.text_style}
								style={{
									color: '#0E7673',
									fontSize: '14px',
									fontWeight: '500',
									marginTop: '20px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '5px'
								}}
								onClick={() => setViewToggle(!viewToggle)}>
								View less{' '}
								<MdKeyboardArrowUp
									style={{ color: '#0E7673', fontSize: '18px', fontWeight: '400', cursor: 'pointer' }}
								/>
							</p>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default AcknowledgeCongratulations;
